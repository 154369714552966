@mixin background-image($image) {
  z-index: -1;
  min-height: 100%;
  min-width: 1024px;

  /* Set up proportionate scaling */
  width: 100%;
  height: auto;

  /* Set up positioning */
  position: fixed;
  top: 0;
  left: 0;

  background: $image no-repeat;
  background-size: cover;
}

@mixin icon-content($icon) {
  content: $icon;
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}

@mixin spin-icon-on-hover() {
  &:hover {
    .fa-spin-hover {
      animation: fa-spin 2s infinite linear;

      @include edge-only() {
        animation: none !important;
      }
    }
  }
}

@mixin edge-only {
  @supports (-ms-ime-align: auto) {
    @content;
  }
}

@mixin hide-scrollbar {
  -ms-overflow-style: none; // EDGE/IE
  scrollbar-width: none; // Firefox
  &::-webkit-scrollbar {
    display: none; // Safari and Chrome
  }
}

@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white, 50%)) {
  // For Google Chrome
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
  }

  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
  }

  // For Internet Explorer
  & {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }
}
