@import '../variables';

.btn {
  &.btn-text {
    background: none;
    border: none;
    outline: none;

    &:focus {
      box-shadow: none;
    }

    &.btn-white {
      color: $whiteColor;

      &:hover {
        color: rgba($color: $whiteColor, $alpha: 0.75);
      }
    }
  }

  &.btn-secondary-green {
    background-color: $secondaryGreenColor;
    color: $whiteColor;
  }

  &.btn-secondary-green:hover {
    color: $whiteColor;
  }

  &.btn-secondary-red {
    background-color: $secondaryMaroonColor;
    color: $whiteColor;
  }

  &.btn-secondary-red:hover {
    background-color: $secondaryMaroonColor;
    color: $whiteColor;
  }

  &.btn-tab {
    color: $textColor;
    text-align: left;
    padding-left: 1rem;
    text-transform: uppercase;
    border: 1px solid $primaryGreyColor;
    margin: 5px 10px;
    border-radius: 5px !important;
    background-color: $backgroundColor;
    font-size: 0.75rem;

    &.active {
      .btn-content {
        border-bottom: 3px solid red;
      }
    }

    &.complete {
      background-color: $secondaryGreenColor;
      color: $whiteColor;
    }
  }

  &.btn-red {
    color: $whiteColor;
    background-color: $primaryRedColor;

    &:hover {
      color: $whiteColor;
      background-color: $secondaryMaroonColor;
      border-color: #bd2130;
    }

    &:focus {
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
    }
  }

  &.btn-xs {
    padding: 0.15rem 0.3rem;
    font-size: $smallFontSize;
    line-height: 1.3;
    border-radius: 0.2rem;
  }

  &.clickable {
    cursor: pointer;
  }

  &.no-box-shadow,
  &.no-box-shadow:active {
    box-shadow: none;
  }

  &.disabled {
    pointer-events: none;
  }
}

button.button-border {
  border: 1px solid $tableBorderColor;
}
