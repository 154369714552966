@import '../variables';
@import '../mixins';

.card-form {
  .card-body {
    padding: 2.25rem;
  }
  .form-control {
    height: 3.2em;
  }

  .form-label-group {
    margin-bottom: 1rem;
  }
}
