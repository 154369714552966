@import './variables';

.body-container {
  background-color: $secondaryMaroonColor;
  min-height: 100vh;
  height: 100vh;
}

.main-container-parent {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  padding-bottom: 0.5rem;
}

.main-container {
  background-color: $backgroundColor;
  border-radius: 5px;
  padding: 1rem;
  min-height: calc(100vh - 81px);
  height: calc(100vh - 81px);
}

.full-screen-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100%;
  height: 100vh;

  .full-screen-page-content {
    width: 100%;
    align-self: center;
    padding: 15px;
  }
}

.w-40 {
  width: 40% !important;
}

.tab-item-layout {
  min-height: calc(100vh - 142px);
  height: calc(100vh - 142px);
  overflow-y: auto;

  @include scrollbars(0.5em, slategray);

  .tab-top-header {
    background-color: $backgroundColor;
    z-index: 1000 !important;
  }
}
