@import '../variables';

.text-danger {
  &.disabled,
  &:disabled {
    color: $darkGreyColor !important;
  }
}

.text-valid {
  color: $secondaryGreenColor;
}

.text-invalid {
  color: $primaryRedColor;
}

.text-default,
.text-black {
  color: $blackColor;
}

.text-grey {
  color: $greyColor;
}

.text-extra-large {
  font-size: $extraLargeFontSize;
}

.text-large {
  font-size: $largeFontSize;
}

.text-medium-small {
  font-size: $mediumSmallFontSize;
}

.text-medium {
  font-size: $mediumFontSize;
}

.text-medium-large {
  font-size: $mediumLargeFontSize;
}

.text-small {
  font-size: $smallFontSize;
}

.text-xs {
  font-size: $extraSmallFontSize;
}

.text-tiny {
  font-size: $tinyFontSize;
}

.text-transform-none {
  text-transform: none;
}

.text-break-all-words {
  word-break: break-all;
}

.text-disabled {
  color: $disabledColor;
}

.text-secondary-primary {
  color: $secondaryPrimaryColor;
}
