@import '../variables';

a {
  &.text-link {
    text-decoration: none;
    color: $textColor;

    &:hover {
      color: $textColor;
    }
  }

  &.disabled {
    &:not(.btn) {
      color: $greyColor !important;
      pointer-events: none;
    }
  }

  &.clickable {
    cursor: pointer;

    &:not(.disabled):not(.text-link) {
      color: $secondaryBlueColor;

      &:hover {
        color: $secondaryBlueColor;
      }
    }
  }
}
