@use '@angular/material' as mat;
@font-face {
  font-family: 'paratype-futura';
  src: url('../fonts/ParaType-Futura-PT-Medium.woff') format('woff'),
    url('../fonts/ParaType-Futura-PT-Medium.otf') format('opentype');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'paratype-futura';
  src: url('../fonts/ParaType-Futura-PT-Bold.woff') format('woff'),
    url('../fonts/ParaType-Futura-PT-Bold.otf') format('opentype');
  font-style: normal;
  font-weight: 700;
}

$paratype-futura-typography: mat.define-typography-config(
  $font-family: 'paratype-futura, arial, sans-serif',
);
@include mat.all-component-typographies($paratype-futura-typography);
