// based on https://getbootstrap.com/docs/4.2/examples/floating-labels/
// + IE11/EDGE support

$floatingLabelColor: #495057;
$floatingLabelHasValueColor: #777;
$floatingLabelFontSize: 12px;
$floatingLabelTransition: all 0.1s ease-in-out;
$floatingLabelInputHeight: 3.125rem;
$floatingLabelInputPadding: 0.75rem;

@mixin input-has-value() {
  padding-top: 1.25rem;
  padding-bottom: 0.25rem;
}

@mixin label-has-value() {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: $floatingLabelFontSize;
  color: $floatingLabelHasValueColor;
}

.floating-label-group {
  position: relative;

  & > input,
  & > select,
  & > label {
    height: $floatingLabelInputHeight;
    padding: $floatingLabelInputPadding;
  }

  & > label {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    margin-bottom: 0; /* Override default `<label>` margin */
    line-height: 1.5;
    color: $floatingLabelColor;
    pointer-events: none;
    cursor: text; /* Match the input under the label */
    border: 1px solid transparent;
    border-radius: 0.25rem;
    transition: $floatingLabelTransition;
  }

  // enforce transparent color
  // importantL keep browser-specific selectors separately
  & input::-webkit-input-placeholder {
    color: transparent;
  }

  & input:-ms-input-placeholder {
    color: transparent;
  }

  & input::-ms-input-placeholder {
    color: transparent;
  }

  & input::-moz-placeholder {
    color: transparent;
  }

  & input::placeholder {
    color: transparent;
  }

  & input:not(:placeholder-shown) {
    @include input-has-value();
  }

  & input.has-value,
  & select.has-value {
    @include input-has-value();
  }

  & input.has-value ~ label,
  & select.has-value ~ label {
    @include label-has-value();
  }

  & input:not(:placeholder-shown) ~ label {
    @include label-has-value();
  }
}

/* Fallback for Edge
-------------------------------------------------- */
@supports (-ms-ime-align: auto) {
  .floating-label-group > input:not(.has-value) ~ label {
    display: none;
  }
  .floating-label-group input::-ms-input-placeholder {
    color: $floatingLabelHasValueColor;
  }

  .floating-label-group input.has-value,
  .floating-label-group select.has-value {
    @include input-has-value();
  }

  .floating-label-group input.has-value ~ label,
  .floating-label-group select.has-value ~ label {
    @include label-has-value();
  }
}

/* Fallback for IE
-------------------------------------------------- */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .floating-label-group > input:not(.has-value) ~ label {
    display: none;
  }
  .floating-label-group input:-ms-input-placeholder {
    color: $floatingLabelHasValueColor;
  }

  .floating-label-group input.has-value,
  .floating-label-group select.has-value {
    @include input-has-value();
  }

  .floating-label-group input.has-value ~ label,
  .floating-label-group select.has-value ~ label {
    @include label-has-value();
  }
}
