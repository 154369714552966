@import '../variables';

.tooltip {
  &.wide-tooltip {
    .tooltip-inner {
      max-width: 500px;
      width: 500px;
      font-size: 0.8rem;
      text-align: left;
    }
  }

  &.medium-large-size-tooltip {
    .tooltip-inner {
      max-width: 350px;
      width: auto;
      font-size: 0.8rem;
      text-align: left;
      white-space: pre-wrap;
    }
  }

  &.medium-size-tooltip {
    .tooltip-inner {
      max-width: 250px;
      width: 250px;
      font-size: 0.8rem;
      text-align: left;
    }
  }

  &.tooltip-with-border {
    .tooltip-inner {
      border: 2px solid $whiteColor;
    }
  }

  &.tooltip-no-wrap {
    .tooltip-inner {
      white-space: nowrap;
    }
  }
}
