@import '../variables';

$modalZIndex: 1050;
$scrollableContentMaxHeight: calc(100vh - 3.5rem);

.modal {
  .modal-content {
    border-radius: 13px;

    .horizontal-modal-content {
      .modal-header {
        background-color: $secondaryMaroonColor;
        color: $whiteColor;
        height: 100%;
        border-radius: 10px 0 0 10px;
        border: none;
      }

      .modal-footer {
        border: none;
      }
    }
  }

  &.modal-scrollable {
    .modal-dialog {
      max-height: $scrollableContentMaxHeight;
      .modal-content {
        max-height: $scrollableContentMaxHeight;
      }
    }
  }

  &.full-screen-modal {
    .modal-dialog {
      max-width: calc(100% - 4rem);
    }
  }
}
