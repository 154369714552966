@import '../variables';

$infiniteLoaderBar1Color: $secondaryBlueColor;
$infiniteLoaderBar2Color: $primaryRedColor;
$infiniteLoaderBarLineHeight: 6px;
$infiniteLoaderBarLineLength: 200px;

@keyframes infinite-loader-bar-1-animation {
  from {
    left: -$infiniteLoaderBarLineLength;
    width: 30%;
    background-color: $infiniteLoaderBar1Color;
  }
  50% {
    width: 30%;
  }
  70% {
    width: 70%;
    background-color: $infiniteLoaderBar1Color;
  }
  80% {
    left: 50%;
  }
  95% {
    left: 120%;
    background-color: $infiniteLoaderBar1Color;
  }
  to {
    left: 100%;
    background-color: $infiniteLoaderBar1Color;
  }
}
@keyframes infinite-loader-bar-2-animation {
  from {
    left: -$infiniteLoaderBarLineLength;
    width: 30%;
    background-color: $infiniteLoaderBar2Color;
  }
  50% {
    width: 30%;
  }
  70% {
    width: 70%;
    background-color: $infiniteLoaderBar2Color;
  }
  80% {
    left: 50%;
  }
  95% {
    left: 120%;
    background-color: $infiniteLoaderBar2Color;
  }
  to {
    left: 100%;
    background-color: $infiniteLoaderBar2Color;
  }
}

.loader {
  &.infinite-loader-bar {
    height: $infiniteLoaderBarLineHeight;
    width: 100%;
    position: relative;
    overflow: hidden;
    background-color: $whiteColor;

    .infinite-loader-bar-1 {
      display: block;
      position: absolute;
      content: '';
      left: -$infiniteLoaderBarLineLength;
      width: $infiniteLoaderBarLineLength;
      height: $infiniteLoaderBarLineHeight;
      background-color: $infiniteLoaderBar1Color;
      animation: infinite-loader-bar-1-animation 1s linear infinite;
    }

    .infinite-loader-bar-2 {
      display: block;
      position: absolute;
      content: '';
      left: -$infiniteLoaderBarLineLength;
      width: $infiniteLoaderBarLineLength;
      height: $infiniteLoaderBarLineHeight;
      background-color: $infiniteLoaderBar2Color;
      animation: infinite-loader-bar-2-animation 3s linear infinite;
      opacity: 0.6;
    }
  }
}

/*--------------
  Overlay loader
---------------*/

.overlay-loader {
  position: relative;

  &.no-background {
    &:before {
      background: none;
      opacity: 1;
    }
  }

  &.dark-background {
    &:before {
      background-color: $blackColor;
      opacity: 0.8;
    }
  }

  &:before {
    position: absolute;
    content: '';
    top: 0%;
    left: 0%;
    background-color: $whiteColor;
    opacity: 0.8;
    width: 100%;
    height: 100%;
    z-index: 100;
  }

  &:after {
    position: absolute;
    content: '';
    top: 50%;
    left: 50%;
    margin: -1.5em 0em 0em -1.5em;
    width: 3em;
    height: 3em;
    -webkit-animation: overlay-loader-spin 0.6s linear;
    animation: overlay-loader-spin 0.6s linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    border-radius: 500rem;
    border-color: $darkGreyColor rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1);
    border-style: solid;
    border-width: 0.2em;
    -webkit-box-shadow: 0px 0px 0px 1px transparent;
    box-shadow: 0px 0px 0px 1px transparent;
    visibility: visible;
    z-index: 101;
  }
}

@-webkit-keyframes overlay-loader-spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes overlay-loader-spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
