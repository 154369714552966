@import '../variables';
@import '../mixins';

$altTableWhiteRowColor: $altLightGreyColor;
$altTableWhiteRowHoverColor: #eff3f6;

.table {
  &.data-table {
    clear: both;
    margin-top: 6px;
    margin-bottom: 6px;

    td,
    th {
      box-sizing: content-box;

      .simple-table-header {
        white-space: nowrap;

        &:hover {
          color: $blackColor;
        }
      }
    }

    tbody {
      .data-table-title-column-container,
      .data-table-truncate-column-container {
        display: table;
        table-layout: fixed;
        width: 95%;
        white-space: nowrap;
        border-spacing: 0;
      }

      .data-table-title-column-container > *,
      .data-table-truncate-column-container > * {
        display: table-cell;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      tr {
        .actions-toggle {
          @include spin-icon-on-hover();
        }
      }
    }

    &.alt-table-white {
      background-color: $whiteColor;
      thead {
        th {
          color: $greyColor;
          text-transform: uppercase;
          font-size: $smallFontSize;
          padding: 0 10px 10px 10px;
          font-weight: 500;
          white-space: none;
          border: none;

          &.icon-column {
            width: 150px;
          }
          &.sm-column {
            width: 75px;
          }
          &.xs-column {
            width: 75px;
          }
          &.action-column {
            width: 100px;
          }
        }
      }

      tbody {
        tr {
          &.clickable-table-row {
            cursor: pointer;

            &:hover {
              background-color: $altTableWhiteRowHoverColor;
            }
          }

          .alt-table-title-column {
            .alt-table-column-header {
              font-weight: 700;
            }
            .alt-table-column-subheader {
              font-size: 14px;
              font-size: $mediumSmallFontSize;
              color: $greyColor;
            }
          }

          td {
            padding: 0.4rem 0.75rem;

            &.clickable-table-column {
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  &.table-align-middle {
    tbody {
      tr {
        td {
          vertical-align: middle;
        }
      }
    }
  }

  &.border-separate {
    border-collapse: separate;
    border-spacing: 0;

    tbody:last-child {
      td {
        border-bottom: 1px solid $tableBorderColor;
      }
    }
  }

  &.table-fixed {
    table-layout: fixed;
  }
}

.table-container {
  border: none;
  box-shadow: 1px 0px 20px $boxShadowColor;
}

.table-toolbar {
  top: 130px;
  padding-top: 17px;
  padding-bottom: 13px;
  background-color: $altBackgroundColor;

  .page-size {
    min-width: 70px;
  }
}
