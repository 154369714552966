// primary
$primaryBlackColor: #000000;
$primaryRedColor: #ed1c24;
$primaryGreyColor: #d6d6d6;
$primaryGreenColor: #008000;

// secondary
$secondaryBlueColor: #0e5b6e;
$secondaryYellowColor: #f79428;
$secondaryMaroonColor: #af1d30;
$secondaryGreenColor: #0c9976;
$secondaryAccentColor: #e040fb;
$secondaryPrimaryColor: #005fcc;

$whiteColor: #ffffff;
$blackColor: #000000;
$greyColor: #868686;
$darkGreyColor: #6c757d;
$textColor: #212529;

$lightGreyColor: #d6d6d6;
$altLightGreyColor: #f8f8f8;

$backgroundColor: #f7f7f7;
$altBackgroundColor: #fbfbfb;

$tableBorderColor: #dee2e6;

$baseFontSize: 0.92rem;
$tinyFontSize: 0.55rem;
$extraSmallFontSize: 0.65rem;
$smallFontSize: 0.75rem;
$mediumSmallFontSize: 0.83rem;
$mediumFontSize: $baseFontSize;
$mediumLargeFontSize: 1rem;
$largeFontSize: 1.25rem;
$extraLargeFontSize: 1.5rem;

$boxShadowColor: rgba(0, 0, 0, 0.05);
$disabledColor: rgba(0, 0, 0, 0.38);
