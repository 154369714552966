/* You can add global styles to this file, and also import other style files */
@import 'src/assets/styles/index';

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';

/* Import custom font */
@import './assets/styles/paratype-futura-typography';

html,
body {
  height: 100%;
}
body {
  font-family: 'paratype-futura', 'arial', sans-serif;
  margin: 0;
}

a {
  color: #0366d6;
}

.disabled-element {
  pointer-events: none;
  opacity: 0.5;
}

mat-dialog-container {
  background: $backgroundColor !important;
}

.mat-checkbox-checked .mat-checkbox-background {
  background-color: $primaryGreenColor !important;
}

.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
  background-color: $primaryGreenColor !important;
}

.permissions-filter-value.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 10px 0px;
  border-top-width: 8px;
}

.permissions-input-field-value {
  .mat-form-field-wrapper {
    padding-bottom: 10px !important;
  }
}

.manage-permissions-dialog.mat-dialog-content {
  overflow-y: hidden;
  max-height: 95vh;
}

.mat-dialog-container {
  @include scrollbars(7px, $greyColor);
}

.mat-selection-list:focus-visible {
  outline-width: 0px;
}

.app-snack-bar-container.mat-snack-bar-container {
  color: $lightGreyColor;
}
.app-snack-bar-container .mat-simple-snackbar-action {
  color: $secondaryGreenColor;
}
