@import '../variables';

$rectangleAlertPrimaryBorderColor: #b8daff;

.alert {
  &.alert-rectangle {
    border: 0px solid transparent;
    border-left: 5px solid;
    border-radius: 0;

    &.alert-primary {
      border-color: $rectangleAlertPrimaryBorderColor;
    }
  }

  &.alert-side {
    border: 0px solid transparent;
    border-left: 5px solid;
    border-radius: 0;

    &.alert-red {
      border-color: $primaryRedColor;
      color: initial;
      background-color: initial;
    }

    &.alert-primary {
      border-color: $rectangleAlertPrimaryBorderColor;
      color: initial;
      background-color: initial;
    }
  }

  &.alert-green {
    background-color: $secondaryGreenColor;
    color: $whiteColor;
  }

  &.alert-red {
    background-color: $primaryRedColor;
    color: $whiteColor;
  }
}
