// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
@import '../variables';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$ManagementDocApp-primary: mat.define-palette(mat.$indigo-palette); // $mat-indigo
$ManagementDocApp-accent: mat.define-palette(mat.$purple-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$ManagementDocApp-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$ManagementDocApp-theme: mat.define-light-theme(
  (
    color: (
      primary: $ManagementDocApp-primary,
      accent: $ManagementDocApp-accent,
      warn: $ManagementDocApp-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($ManagementDocApp-theme);

.body-container,
.cdk-overlay-container {
  .mat-form-field-appearance-standard .mat-form-field-flex {
    padding-top: 0px;
  }

  .mat-form-field-appearance-outline .mat-form-field-outline {
    background-color: $whiteColor;
  }

  .mat-button-base,
  .mat-menu-item {
    &:focus {
      outline: none;
    }
  }
}

$smallButtonSize: 22px;

.mat-icon-button {
  &.small-button {
    width: $smallButtonSize;
    height: $smallButtonSize;
    line-height: $smallButtonSize;

    .mat-icon {
      font-size: $smallButtonSize;
      width: $smallButtonSize;
      height: $smallButtonSize;
      line-height: $smallButtonSize;
    }
  }
}

.mat-autocomplete-panel {
  @include scrollbars(0.5em, slategray);

  .mat-option {
    line-height: 40px;
    height: 40px;
  }

  .mat-optgroup-label {
    line-height: 24px;
    height: 24px;
    margin-top: 8px;
    margin-bottom: 0;
  }
}

.body-container,
.cdk-overlay-container {
  z-index: 1020;
  .mat-paginator {
    background-color: transparent;
    .mat-paginator-container {
      min-height: auto;
    }
  }

  .mat-form-field-appearance-outline {
    .mat-form-field-subscript-wrapper {
      padding: 0 0.5em;
    }

    &.mat-form-field-disabled {
      .mat-form-field-outline {
        color: rgba(0, 0, 0, 0.12);
      }
      .mat-form-field-label {
        color: rgba(0, 0, 0, 0.6);
      }
    }
  }

  .readonly-container {
    .mat-form-field-appearance-outline {
      .mat-form-field-flex {
        min-height: 63px;
      }

      &.mat-form-field-disabled {
        .mat-form-field-outline {
          color: rgba(0, 0, 0, 0.12);
        }
        .mat-form-field-label {
          color: rgba(0, 0, 0, 0.6);
        }
      }

      .mat-input-element:disabled,
      .mat-form-field-type-mat-native-select.mat-form-field-disabled.mat-form-field-infix::after,
      .mat-radio-button.mat-radio-disabled .mat-radio-label-content,
      .mat-select-disabled .mat-select-value,
      .text-disabled {
        color: currentColor;
      }

      .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
      .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
        border-color: mat.get-color-from-palette(
          $ManagementDocApp-accent,
          A200
        ); //#e040fb; // $ManagementDocApp-primary.A200;
      }

      .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
      .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
        background-color: mat.get-color-from-palette($ManagementDocApp-accent, A200); // $ManagementDocApp-primary.A200;
      }
    }
  }
}
